export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'support',
    label: 'How To Start',
  },
  {
    path: 'features',
    label: 'Feature',
  },
  // {
  //   path: 'pricing',
  //   label: 'Pricing',
  // },
  // {
  //   path: 'testimonials',
  //   label: 'Testimonials',
  // },
];
